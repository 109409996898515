import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Commponent/Layout/Layout'; // Ensure this path is correct
import Home from './Commponent/Home/Home';
import Sliders from './Commponent/Sliders/Sliders';
import Services from './Commponent/services/Services';
import Works from './Commponent/works/works';
import Contact from './Commponent/Contact/Contact';
import Socials from './Commponent/Socials/Socials';
import WhoAreWe from './Commponent/WhoareWe/WhoAreWe';
import Login from './Commponent/Home/Login'; // Import the Login component
import ProtectedRoute from './Commponent/Protectroute/ProtectedRoute'; // Ensure this path is correct
import Meta from './Commponent/Meta/Meta';

function App() {
  return (
    <div className="App">
      <Router basename="/dashboard">
        <Routes>
          {/* Public Route for Login */}
          <Route path="/login" element={<Login />} />
          
          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Sliders />} />
            <Route path="home" element={<Home />} />
            <Route path="sliders" element={<Sliders />} />
            <Route path="services" element={<Services />} />
            <Route path="works" element={<Works />} />
            <Route path="contact" element={<Contact />} />
            <Route path="socials" element={<Socials />} />
            <Route path="whoarewe" element={<WhoAreWe />} />
            <Route path="meta" element={<Meta />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
